import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';

import { Phase, PhaseService } from '../phase.service';

interface PhaseConfig {
  name: string
  description: string
  duration: number
}

@Component({
  selector: 'app-config-page',
  templateUrl: './config-page.component.html',
  styleUrls: ['./config-page.component.scss']
})
export class ConfigPageComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private phaseService: PhaseService,
  ) { }

  configForm = this.fb.group({
    phases: this.fb.array([]),
    password: ['']
  })

  ngOnInit() {
    this.phaseService.getPhases().subscribe((phases: Phase[]) => {
      for (let phase of phases) {
        this.addConfigPhase(phase.name, phase.description, phase.duration)
      }
    })

  }

  get configPhases() {
    return this.configForm.get('phases') as FormArray;
  }

  addConfigPhase(name="", description="", duration=0) {
    this.configPhases.push(this.fb.group({
      name: [name],
      description: [description],
      duration: [duration]
    }))
  }

  removeConfigPhase(i) {
    this.configPhases.removeAt(i)
  }

  movePhase(i, m) {
    let newIndex = i + m;
    if (newIndex < 0) newIndex = 0;

    let phase = this.configPhases.at(i);
    this.configPhases.removeAt(i);
    this.configPhases.insert(newIndex, phase);
  }

  reload() {
    if (window.confirm("Discard changes?")) {
      window.location.reload()
    }
  }

  applyChanges() {
    this.phaseService.updatePhases(this.configPhases.value, this.configForm.get('password').value).subscribe(res => {
      alert(res)
      window.location.href = "/"
    }, (err => {
      if (err.status == 403) {
        alert("Incorrect password.")
      }
    }))
    this.configForm.get('password').reset()
  }

  addPhase() {
    this.addConfigPhase()
  }

}
