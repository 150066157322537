import { Component, OnInit } from '@angular/core';

import moment, { Moment } from 'moment-business-days';
import { Phase, PhaseService } from '../phase.service';

interface Month {
  name: string
  year: number
  days: Day[],
  buffer: any[]
}

interface Day {
  color: string
  tooltip: string
  moment: Moment,
  border: boolean
}

@Component({
  selector: 'app-calendar-page',
  templateUrl: './calendar-page.component.html',
  styleUrls: ['./calendar-page.component.scss']
})
export class CalendarPageComponent implements OnInit {

  constructor(
    private phaseService: PhaseService
  ) { }

  private phases: Phase[]
  private months: Month[] = []
  private holidays = {
    '01-01': 'New Year\'s Day',
    // '04-10': '',
    // '05-25': 'Memorial Day',
    // '07-03': '',
    // '09-07': 'Labor Day',
    // '11-26': 'Thanksgiving Eve',
    // '11-27': 'Thanksgiving Day',
    '12-24': 'Christmas Eve',
    '12-25': 'Christmas Day',
    '12-31': 'New Year\'s Eve'
  }

  public title = 'Trellis Date Estimator';
  private rangeFormat: string = 'MMM Do'
  private calCols = 2

  private startDate
  private endDate

  // Triggers
  ngOnInit() {
    // Load phase information.
    this.phaseService.getPhases().subscribe(phases => {
      this.phases = phases

    // Set holidays.
    moment.updateLocale('us', {
      holidays: Object.keys(this.holidays),
      holidayFormat: 'MM-DD'
    })

    this.startDate = moment().format()
    this.calculateDates(this.startDate, false)
    })
  }

  onStartDateSet(event) {
    this.endDate = ''
    this.calculateDates(event.value, false)
  }

  onEndDateSet(event) {
    this.startDate = ''
    this.calculateDates(event.value, true)
  }

  onResize(event) {
    let width = event.target.innerWidth;
    if (width < 500 || (width >= 600 && width < 900)) {
      this.calCols = 1;
    }
    else {
      this.calCols = 2;
    }
  }


  // Functions
  calculateDates(dateString, reverse: boolean) {
    this.months = []
    let dateCursor = moment(dateString)
    let currentMonth: number = -1

    // When start date set.
    if (!reverse) {
      dateCursor = dateCursor.prevBusinessDay().nextBusinessDay()
      for (let phase of this.phases) {
        phase.range.start = dateCursor.format(this.rangeFormat)
  
        for (let i = 0; i < phase.duration; i++) {
          if (dateCursor.month() !== currentMonth) {
            currentMonth = dateCursor.month()
            this.months.push(this.createMonth(dateCursor))
          }
          this.months[this.months.length-1].days[dateCursor.date()-1].color = phase.calColor
          this.months[this.months.length-1].days[dateCursor.date()-1].tooltip = phase.name
          dateCursor = dateCursor.nextBusinessDay()
        }
  
        phase.range.end = moment(dateCursor).prevBusinessDay().format(this.rangeFormat)
        phase.range.display = this.createPhaseRangeDisplay(phase.range.start, phase.range.end)
      }
    }
    // When end date set.
    else {
      dateCursor = dateCursor.prevBusinessDay().nextBusinessDay()
      for (let i = this.phases.length-1; i >= 0; i--) {
        let phase = this.phases[i];
        phase.range.end = dateCursor.format(this.rangeFormat)
  
        for (let i = 0; i < phase.duration; i++) {
          if (dateCursor.month() !== currentMonth) {
            currentMonth = dateCursor.month()
            this.months.unshift(this.createMonth(dateCursor))
          }
          this.months[0].days[dateCursor.date()-1].color = phase.calColor
          this.months[0].days[dateCursor.date()-1].tooltip = phase.name
          if (dateCursor.isBefore(moment())) {
            this.months[0].days[dateCursor.date()-1].color = '#dd3333'
            this.months[0].days[dateCursor.date()-1].tooltip += ' - Date Passed'
            this.months[0].days[dateCursor.date()-1].border = true
          }
          dateCursor = dateCursor.prevBusinessDay()
        }
  
        phase.range.start = moment(dateCursor).nextBusinessDay().format(this.rangeFormat)
        phase.range.display = this.createPhaseRangeDisplay(phase.range.start, phase.range.end)
      }
    }
  }

  createMonth(date): Month {
    let days: Day[] = []
    for (let i = 1; i <= date.daysInMonth(); i++) {
      let m = moment(date.format('YYYY-MM') + '-' + String(i).padStart(2, '0'))
      let color = ''
      let tooltip = ''
      if (m.format('MM-DD') in this.holidays) {
        color = '#dddddd';
        tooltip = this.holidays[m.format('MM-DD')]
      }
      let day: Day = {
        color: color,
        tooltip: tooltip,
        moment: m,
        border: false
      }
      days.push(day)
    }

    return {
      name: date.format('MMMM'),
      year: parseInt(date.format('YYYY')),
      days: days,
      buffer: Array(moment(date).startOf('month').weekday())
    }
  }

  createPhaseRangeDisplay(start, end): string {
    let display = start
    if (start !== end) {
      display += ' - ' + end
    }
    return display
  }

}
