import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Phase {
  name: string
  description?: string
  duration: number
  color: string,
  calColor: string,
  range: PhaseDateRange
}

export interface PhaseDateRange {
  start: string,
  end: string,
  display: string
}


@Injectable({
  providedIn: 'root'
})
export class PhaseService {

  phasesUrl: string = "https://s3.amazonaws.com/trellis.date-estimator.phases/phases.json";
  postUrl: string = "https://gxz9yaqwli.execute-api.us-east-1.amazonaws.com/update_phases";
  colors: string[] = [
    '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4','#00bcd4',
    '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffc107', '#ff9800', '#ff5722'
  ]
  calColors: string[] = [
    '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb', '#64b5f6', '#4fc3f7', '#4dd0e1',
    '#4db6ac', '#81c784', '#aed581', '#dce775', '#ffd54f', '#ffb74d', '#ff8a65'
  ]

  constructor(
    private http: HttpClient
  ) { }

  getPhases(): Observable<Phase[]> {
    return this.http.get(this.phasesUrl).pipe(map(data => {
      let phases = [];
      for (let i = 0; i < data['phases'].length; i++) {
        phases.push({
          name: data['phases'][i].name,
          description: data['phases'][i].description,
          duration: data['phases'][i].duration,
          color: this.colors[i%15],
          calColor: this.calColors[i%15],
          range: {start: '', end: '', display: ''}
        });
      }
      return phases;
    }));
  }

  updatePhases(phases, password) {
    return this.http.post(this.postUrl, {password, phases})
  }
}
